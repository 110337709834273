import React, { useState, useContext, useEffect } from "react";
import { Container } from "aurelia-framework";
import { formatPriceWithCommas } from "../helper/utility";
import update from "immutability-helper";
import {
    Panel,
    Divider,
    Checkbox,
    Row,
    Col,
    Button,
    IconButton,
    ButtonToolbar,
    InputGroup,
    InputNumber, SelectPicker,
} from "rsuite";
import moment from "moment";
import {OfferPreviewDetails} from "./offer-preview-details";
import {OfferPreviewDuration} from "./offer-preview-duration";
import {OfferPreviewSummary} from "./offer-preview-summary";
import {OfferPreviewPricing} from "./offer-preview-pricing";
import {OrderContext} from "../helper/order-context";
import {Client} from "../../../api/client";
import "./offer-preview.less";
import {Nl2BrValueConverter} from "../../../value-converter/nl2br-value-converter";

export const ACCOMMODATION_DISPLAY_PICKER_VALUES = [
    {
        value: "complete",
        label: "Komplett",
    },
    {
        value: "example",
        label: "Beispielhaft",
    },
    {
        value: "category",
        label: "Kategory",
    },
];

export const OfferPreview = ({
                                 selectedSearchItem,
                                 participants,
                                 firstDate,
                                 clearSelectedItem,
                                 fromDate,
                                 toDate,
                                 offerStartDate,
                                 defaultAccommodationDisplay
                             }) => {
    const client = Container.instance.get(Client);
    const nl2br = Container.instance.get(Nl2BrValueConverter);

    const [accommodationDuration, setAccommodationDuration] = useState(selectedSearchItem.durationDays ?? 1);
    const [timeAndPriceOptions, setTimeAndPriceOptions] = useState({
        day1: null,
        day2: null,
        timeAndPrice1: [],
        timeAndPrice2: [],
    });

    const [selectedDate, setSelectedDate] = useState(firstDate);

    const [
        selectedTimeAndPriceOption,
        setSelectedTimeAndPriceOption,
    ] = useState(null);
    const [selectedTime, setSelectedTime] = useState();
    const [hintsOpeningTimes, setHintsOpeningTimes] = useState();
    const [isServiceOptional, setIsServiceOptional] = useState(false);
    const [purchaseTime, setPurchaseTime] = useState(false);
    const [purchaseTimeLimit, setPurchaseTimeLimit] = useState(false);
    const [accommodationDisplay, setAccommodationDisplay] = useState(defaultAccommodationDisplay ?? 'complete');
    const [additionalOptions, setAdditionalOptions] = useState([]);
    const [mandatoryItems, setMandatoryItems] = useState([]);
    const [selectedAdditionalOptions, setSelectedAdditionalOptions] = useState(
        {}
    );
    const { title, product, provider, id, modelId } = selectedSearchItem;
    const { description, teasers, images } = product;

    const displayDuration = selectedSearchItem.dynamicDuration ?? false;

    const isAccommodationTab =
        provider && provider.includes("tourism-accommodation");
    const isFlightTab =
        provider && provider.includes("tourism-flight");
    const isServiceTab =
        provider && provider.includes("tourism-service");
    const isFerryTab =
        provider && (provider.includes("tourism-ferry") || provider.includes("tourism-ship"));

    const { updateEventItem, order } = useContext(OrderContext);

    useEffect(() => {
        getPricingDetails();
    }, [accommodationDuration, selectedDate]);

    const onOrderConfirm = () => {
        const fromDate = moment.utc(selectedTime.date)
            //Format needed for ferries
            .format('YYYY-MM-DDTHH:mm:ssZ');

        let productProvider = provider;

        if (provider === 'tourism-accommodation/accommodation-interface') {
            productProvider = 'tourism-accommodation/room';
        }

        const payload = {
            provider: productProvider,
            product: {
                id: id,
                modelId: modelId,
            },
            fromDate,
        };

        if (isFlightTab) {
            payload.data = {
                fareResult: payload.product
            };
            delete payload.fromDate;
            delete payload.product;
        }

        if (
            selectedAdditionalOptions &&
            Object.keys(selectedAdditionalOptions).length > 0
        ) {
            payload.configuratorAdditionalOptions = Object.values(
                selectedAdditionalOptions
            );
        }

        if (displayDuration) {
            payload.toDate = moment.utc(fromDate)
                .add(isAccommodationTab ? (accommodationDuration) : (accommodationDuration - 1), "days")
                .toISOString();
        }

        if (isAccommodationTab || isServiceTab || isFerryTab) {
            payload.optional = isServiceOptional;
            payload.purchaseTime = purchaseTime;
            payload.purchaseTimeLimit = purchaseTimeLimit;
        }

        if (isAccommodationTab) {
            payload.accommodationDisplay = accommodationDisplay;
        }

        updateEventItem(payload);
        clearSelectedItem();
    };

    const getPricingDetails = async () => {
        const date = moment(selectedDate).format("YYYY-MM-DD");
        const url = `offer-search-prices/${provider}/${order.id}?amount=${participants}&date=${date}&resultId=${id}&duration=${accommodationDuration}&embeds[]=additionalOptions`;
        try {
            const response = await client.get(url);
            setAdditionalOptions(response.additionalOptions);
            setMandatoryItems(response.mandatoryItems ?? []);
            const details = mapTimeAndPriceInfo(response.prices, response.showPurchasePrice ?? false);
            setTimeAndPriceOptions(details);
            setHintsOpeningTimes(response.hintsOpeningTimes);
        } catch (error) {
            console.error("Error fetching pricings", error);
        }
        setSelectedTime();
    };
    // TIME AND PRICE
    const mapTimeAndPriceInfo = (infos, showPurchase) => {
        const start = infos[0];
        const startUTC = moment.utc(start.fromDate);
        const end = infos[infos.length - 1];
        const mappedTimeAndPriceInfo = {
            day1: startUTC.format("DD.MM.YYYY"),
            day2: moment.utc(end.fromDate).format("DD.MM.YYYY"),
            timeAndPrice1: [],
            timeAndPrice2: [],
        };
        infos.forEach((info) => {
            const dateOriginal = info.fromDate;
            const originalDateUTC = moment.utc(info.fromDate);
            const time = originalDateUTC.format("HH:mm");

            let price;
            let currency;

            if (showPurchase) {
                price = info.purchasePricePerPerson?.amount;
                currency = info.purchasePricePerPerson?.currency;
            } else {
                price = info.pricePerPerson?.amount;
                currency = info.pricePerPerson?.currency;
            }

            const disabled = info.disabled ?? false;
            const disabledReasons = info.disabledReasons ?? false;

            const data = {
                dateOriginal,
                price,
                currency,
                time,
                disabled,
                disabledReasons,
            };

            const isOnStartDay = startUTC.isSame(originalDateUTC, "day");
            if (isOnStartDay) {
                mappedTimeAndPriceInfo.timeAndPrice1 = calculateTimeAndPricePerPerson(
                    mappedTimeAndPriceInfo.timeAndPrice1,
                    data
                );
            } else {
                if (!mappedTimeAndPriceInfo.day2) {
                }
                mappedTimeAndPriceInfo.timeAndPrice2 = calculateTimeAndPricePerPerson(
                    mappedTimeAndPriceInfo.timeAndPrice2,
                    data
                );
            }
        });
        return mappedTimeAndPriceInfo;
    };

    const calculateTimeAndPricePerPerson = (timeAndPrice, data) => {
        const { dateOriginal, price, currency, time, disabled, disabledReasons } = data;
        const index = timeAndPrice.findIndex(
            (timeInfo) => timeInfo.price === price
        );
        if (index > -1) {
            timeAndPrice[index].times.push({ hour: time, date: dateOriginal, disabled: disabled, disabledReasons: disabledReasons });
        } else {
            timeAndPrice.push({
                price,
                currency,
                times: [{ hour: time, date: dateOriginal, disabled: disabled, disabledReasons: disabledReasons }],
            });
        }
        return timeAndPrice;
    };

    const selectTime = (timeInfo, time) => {
        setSelectedTimeAndPriceOption(timeInfo);
        setSelectedTime(time);
    };

    const onChangeDuration = (duration) => {
        setAccommodationDuration(duration);
        setSelectedTimeAndPriceOption(null);
    };

    const changeAdditionalService = (identifier, value) => {
        if (value === 0) {
            setSelectedAdditionalOptions(
                update(selectedAdditionalOptions, { $unset: [identifier] })
            );
        } else {
            if (!selectedAdditionalOptions[identifier]) {
                setSelectedAdditionalOptions(
                    update(selectedAdditionalOptions, {
                        [identifier]: {
                            $set: {
                                amount: value,
                                optional: false,
                                value: identifier,
                            },
                        },
                    })
                );
            } else {
                setSelectedAdditionalOptions(
                    update(selectedAdditionalOptions, {
                        [identifier]: { amount: { $set: value } },
                    })
                );
            }
        }
    };

    return (
        <Panel bordered className="preview-view">
            <OfferPreviewDetails
                title={title}
                teasers={teasers}
                images={images}
                description={description}
                closePreview={clearSelectedItem}
                product={product}
            />
            {displayDuration && (
                <OfferPreviewDuration
                    accommodationDuration={accommodationDuration}
                    setAccommodationDuration={onChangeDuration}
                    isServiceOptional={isServiceOptional}
                    setIsServiceOptional={setIsServiceOptional}
                />
            )}

            { mandatoryItems.length > 0 ? <>
                <h5>inklusive Pflichtzusatzleistungen:</h5>

                {
                    mandatoryItems.map(mandatoryItem => {
                        return <p>
                            {mandatoryItem[0]}: { formatPriceWithCommas(mandatoryItem[1].amount, mandatoryItem[1].currency) }
                        </p>;
                    })
                }

            </> : null}

            {(isAccommodationTab || isServiceTab || isFerryTab) && (
                <div>
                    <Checkbox
                        checked={isServiceOptional}
                        onChange={(value, checked) => {
                            setIsServiceOptional(checked);
                        }}
                    >
                        Optionale Leistung
                    </Checkbox>
                    <div>
                        <label>Fester Einkaufszeitpunkt</label>
                        <input className="form-control"
                               name="purchase-time"
                               type="date"
                               onChange={(event) => {
                                   setPurchaseTime(event.target.value);
                               }}
                        />
                    </div>
                    <div>
                        <label>Einkaufsfrist in Tagen vor dem Leistungsbeginn</label>
                        <input className="form-control"
                               name="purchase-time-limit"
                               type="number"
                               onChange={(event) => {
                                   setPurchaseTimeLimit(event.target.value);
                               }}
                        />
                    </div>
                </div>
            )}

            {(isAccommodationTab && selectedSearchItem?.product?.modelId == 'tourism-accommodation/accommodation') && (
                <div>
                    <div>
                        <label>Anzeige von Hotels</label>
                        <SelectPicker
                            cleanable={false}
                            data={ACCOMMODATION_DISPLAY_PICKER_VALUES}
                            value={accommodationDisplay}
                            onChange={(value) => {
                                setAccommodationDisplay(value);
                            }}
                            className="select-picker-input"
                        />
                    </div>
                </div>
            )}

            <div style={{marginBottom: "10px"}}>
                {additionalOptions && additionalOptions.length > 0 && (
                    <div className="preview-view-heading col-xs-12">
                        <h5>Zusatzleistungen</h5>

                    </div>)
                }
                {additionalOptions && additionalOptions.map((option) => {
                    return (
                        <div
                            className="row"
                            style={{ marginTop: "10px" }}
                            key={option.value}
                        >
                            <div className="col-xs-3">
                                <InputNumber
                                    className="custom-input-number"
                                    value={
                                        selectedAdditionalOptions?.[
                                            option.value
                                        ]?.amount ?? 0
                                    }
                                    onChange={(value) => {
                                        changeAdditionalService(
                                            option.value,
                                            Number(value)
                                        );
                                    }}
                                    max={999999}
                                    min={0}
                                />
                            </div>
                            <div className="col-xs-5">
                                <div>{option.label}</div>
                                {option.provider &&
                                    <a
                                        target={"_blank"}
                                        href={option.provider.url}
                                    >
                                        {option.provider.name}
                                    </a>
                                }
                            </div>
                            <div className="col-xs-4">
                                {selectedAdditionalOptions[option.value] && (
                                    <Checkbox
                                        checked={
                                            selectedAdditionalOptions?.[
                                                option.value
                                            ]?.optional ?? 0
                                        }
                                        onChange={(event, value) => {
                                            setSelectedAdditionalOptions(
                                                update(
                                                    selectedAdditionalOptions,
                                                    {
                                                        [option.value]: {
                                                            optional: {
                                                                $set: value,
                                                            },
                                                        },
                                                    }
                                                )
                                            );
                                        }}
                                    >
                                        Optionale Leistung
                                    </Checkbox>
                                )}
                            </div>
                        </div>
                    );
                })}
            </div>

            {hintsOpeningTimes != null && hintsOpeningTimes != '' ? <>
                <h5>Hinweise zu Öffnungszeiten</h5>
                <p dangerouslySetInnerHTML={{__html: nl2br.toView(hintsOpeningTimes)}}></p></> : null}

            <OfferPreviewPricing
                selectedTime={selectedTime}
                isAccommodationTab={isAccommodationTab}
                timeAndPriceOptions={timeAndPriceOptions}
                onSelectTime={selectTime}
                selectedDate={selectedDate}
                toDate={toDate}
            />
            <ButtonToolbar>
                {moment(new Date(selectedDate)) > moment(new Date(offerStartDate)) ? 
                <Button
                    onClick={() => {
                        setSelectedDate(
                            moment(selectedDate)
                                .subtract(1, "days")
                                .format("YYYY-MM-DD")
                        );
                    }}
                >
                    <i className="fa fa-arrow-left"/>
                </Button>
                :
                <Button disabled>
                    <i className="fa fa-arrow-left"/>
                </Button>
                }

                {moment(new Date(selectedDate)) < moment(new Date(toDate)) ?
                <Button
                onClick={() => {
                    setSelectedDate(
                        moment(selectedDate)
                        .add(1, "days")
                        .format("YYYY-MM-DD")
                        );
                    }}
                >
                    <i className="fa fa-arrow-right"/>
                </Button>
                :
                <Button disabled>
                    <i className="fa fa-arrow-right"/>
                </Button>
                }
            </ButtonToolbar>
            <Divider />
            <OfferPreviewSummary
                participants={participants}
                selectedTimeAndPriceOption={selectedTimeAndPriceOption}
                onOrderConfirm={onOrderConfirm}
                isSubmitDisabled={!selectedTime}
            />
        </Panel>
    );
};
